<template>
  <b-modal
    id="ConfirmCancelPolizaDialog"
    centered
    no-close-on-backdrop
    hide-header-close
    hide-footer
  >
    <template #modal-title>Confirme la cancelación de la póliza</template>

    <template #default="{cancel}">
      <div>
        ¿Desea proceder con la cancelación de la póliza?
      </div>

      <FormulateForm ref="exportForm" #default="{ hasErrors, isLoading }" @submit="submit">
        <!-- Submit -->
        <x-form-footer-buttons
          :disabled="hasErrors || isLoading"
          :loading="isLoading"
          :isEditing="false"
          :isCreating="true"
          btnCreateTitle="Confirmar"
          btnCreateType="submit"
        >
          <template #cancel>
            <b-button variant="black" size="sm" @click="cancel">Cancelar</b-button>
          </template>
        </x-form-footer-buttons>
      </FormulateForm>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'ConfirmCancelacionPolizaDialog',

  methods: {
    async submit () {
      this.$emit('on-submitted')
    }
  }
}
</script>
